/* tslint:disable */
/* eslint-disable */
/**
 * Vision
 *      Louvre / Vision API      This API documentation is fully compatible with OpenAPI specification.      For more information, please visit https://ottozhang.com     
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'cover_image'?: string;
    /**
     * 
     * @type {object}
     * @memberof Activity
     */
    'description'?: object;
    /**
     * 
     * @type {object}
     * @memberof Activity
     */
    'extra'?: object;
    /**
     * 
     * @type {GeometryElement | GeometryCollection}
     * @memberof Activity
     */
    'geometry'?: GeometryElement | GeometryCollection;
    /**
     * 
     * @type {object}
     * @memberof Activity
     */
    'activity_name': object;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'activity_unique_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof Activity
     */
    'kind': Kind;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    'activity_id': number;
}
/**
 * 
 * @export
 * @interface ActivityBrief
 */
export interface ActivityBrief {
    /**
     * 
     * @type {string}
     * @memberof ActivityBrief
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof ActivityBrief
     */
    'kind': Kind;
    /**
     * 
     * @type {number}
     * @memberof ActivityBrief
     */
    'activity_id': number;
    /**
     * 
     * @type {object}
     * @memberof ActivityBrief
     */
    'activity_name': object;
    /**
     * 
     * @type {string}
     * @memberof ActivityBrief
     */
    'cover_image': string;
    /**
     * 
     * @type {GeometryElement | GeometryCollection}
     * @memberof ActivityBrief
     */
    'geometry'?: GeometryElement | GeometryCollection;
}
/**
 * 
 * @export
 * @interface ActivityBriefCollection
 */
export interface ActivityBriefCollection {
    /**
     * 
     * @type {string}
     * @memberof ActivityBriefCollection
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof ActivityBriefCollection
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof ActivityBriefCollection
     */
    'page_token': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityBriefCollection
     */
    'next_page_token': string;
    /**
     * 
     * @type {number}
     * @memberof ActivityBriefCollection
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityBriefCollection
     */
    'total_size': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityBriefCollection
     */
    'total_pages': number;
    /**
     * 
     * @type {Array<ActivityBrief>}
     * @memberof ActivityBriefCollection
     */
    'contents': Array<ActivityBrief>;
}
/**
 * 
 * @export
 * @interface ActivityCollection
 */
export interface ActivityCollection {
    /**
     * 
     * @type {string}
     * @memberof ActivityCollection
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof ActivityCollection
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof ActivityCollection
     */
    'page_token': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityCollection
     */
    'next_page_token': string;
    /**
     * 
     * @type {number}
     * @memberof ActivityCollection
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityCollection
     */
    'total_size': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityCollection
     */
    'total_pages': number;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivityCollection
     */
    'contents': Array<Activity>;
}
/**
 * 
 * @export
 * @interface ActivityCreate
 */
export interface ActivityCreate {
    /**
     * 
     * @type {string}
     * @memberof ActivityCreate
     */
    'cover_image'?: string;
    /**
     * 
     * @type {object}
     * @memberof ActivityCreate
     */
    'description'?: object;
    /**
     * 
     * @type {object}
     * @memberof ActivityCreate
     */
    'extra'?: object;
    /**
     * 
     * @type {GeometryElement | GeometryCollection}
     * @memberof ActivityCreate
     */
    'geometry'?: GeometryElement | GeometryCollection;
    /**
     * 
     * @type {object}
     * @memberof ActivityCreate
     */
    'activity_name': object;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreate
     */
    'activity_unique_id'?: string;
}
/**
 * 
 * @export
 * @interface ActivityKeywords
 */
export interface ActivityKeywords {
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityKeywords
     */
    'keywords': Array<string>;
}
/**
 * 
 * @export
 * @interface ActivityPatch
 */
export interface ActivityPatch {
    /**
     * 
     * @type {string}
     * @memberof ActivityPatch
     */
    'cover_image'?: string;
    /**
     * 
     * @type {object}
     * @memberof ActivityPatch
     */
    'description'?: object;
    /**
     * 
     * @type {object}
     * @memberof ActivityPatch
     */
    'extra'?: object;
    /**
     * 
     * @type {GeometryElement | GeometryCollection}
     * @memberof ActivityPatch
     */
    'geometry'?: GeometryElement | GeometryCollection;
    /**
     * 
     * @type {object}
     * @memberof ActivityPatch
     */
    'activity_name'?: object;
}
/**
 * 
 * @export
 * @interface Artwork
 */
export interface Artwork {
    /**
     * 
     * @type {string}
     * @memberof Artwork
     */
    'cover_image'?: string;
    /**
     * 
     * @type {object}
     * @memberof Artwork
     */
    'description'?: object;
    /**
     * 
     * @type {object}
     * @memberof Artwork
     */
    'extra'?: object;
    /**
     * 
     * @type {GeometryElement | GeometryCollection}
     * @memberof Artwork
     */
    'geometry'?: GeometryElement | GeometryCollection;
    /**
     * 
     * @type {object}
     * @memberof Artwork
     */
    'artwork_name': object;
    /**
     * 
     * @type {number}
     * @memberof Artwork
     */
    'artwork_rate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Artwork
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof Artwork
     */
    'kind': Kind;
    /**
     * 
     * @type {number}
     * @memberof Artwork
     */
    'artwork_id': number;
    /**
     * 
     * @type {string}
     * @memberof Artwork
     */
    'landmark': string;
}
/**
 * 
 * @export
 * @interface ArtworkCollection
 */
export interface ArtworkCollection {
    /**
     * 
     * @type {string}
     * @memberof ArtworkCollection
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof ArtworkCollection
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof ArtworkCollection
     */
    'page_token': string;
    /**
     * 
     * @type {string}
     * @memberof ArtworkCollection
     */
    'next_page_token': string;
    /**
     * 
     * @type {number}
     * @memberof ArtworkCollection
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof ArtworkCollection
     */
    'total_size': number;
    /**
     * 
     * @type {number}
     * @memberof ArtworkCollection
     */
    'total_pages': number;
    /**
     * 
     * @type {Array<Artwork>}
     * @memberof ArtworkCollection
     */
    'contents': Array<Artwork>;
}
/**
 * 
 * @export
 * @interface ArtworkCreate
 */
export interface ArtworkCreate {
    /**
     * 
     * @type {string}
     * @memberof ArtworkCreate
     */
    'cover_image'?: string;
    /**
     * 
     * @type {object}
     * @memberof ArtworkCreate
     */
    'description'?: object;
    /**
     * 
     * @type {object}
     * @memberof ArtworkCreate
     */
    'extra'?: object;
    /**
     * 
     * @type {GeometryElement | GeometryCollection}
     * @memberof ArtworkCreate
     */
    'geometry'?: GeometryElement | GeometryCollection;
    /**
     * 
     * @type {object}
     * @memberof ArtworkCreate
     */
    'artwork_name': object;
    /**
     * 
     * @type {number}
     * @memberof ArtworkCreate
     */
    'artwork_rate'?: number;
}
/**
 * 
 * @export
 * @interface ArtworkPatch
 */
export interface ArtworkPatch {
    /**
     * 
     * @type {string}
     * @memberof ArtworkPatch
     */
    'cover_image'?: string;
    /**
     * 
     * @type {object}
     * @memberof ArtworkPatch
     */
    'description'?: object;
    /**
     * 
     * @type {object}
     * @memberof ArtworkPatch
     */
    'extra'?: object;
    /**
     * 
     * @type {GeometryElement | GeometryCollection}
     * @memberof ArtworkPatch
     */
    'geometry'?: GeometryElement | GeometryCollection;
    /**
     * 
     * @type {object}
     * @memberof ArtworkPatch
     */
    'artwork_name'?: object;
    /**
     * 
     * @type {number}
     * @memberof ArtworkPatch
     */
    'artwork_rate'?: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum Country {
    Aruba = 'Aruba',
    Afghanistan = 'Afghanistan',
    Angola = 'Angola',
    Anguilla = 'Anguilla',
    LandIslands = 'Åland Islands',
    Albania = 'Albania',
    Andorra = 'Andorra',
    UnitedArabEmirates = 'United Arab Emirates',
    Argentina = 'Argentina',
    Armenia = 'Armenia',
    AmericanSamoa = 'American Samoa',
    Antarctica = 'Antarctica',
    FrenchSouthernTerritories = 'French Southern Territories',
    AntiguaAndBarbuda = 'Antigua and Barbuda',
    Australia = 'Australia',
    Austria = 'Austria',
    Azerbaijan = 'Azerbaijan',
    Burundi = 'Burundi',
    Belgium = 'Belgium',
    Benin = 'Benin',
    BonaireSintEustatiusAndSaba = 'Bonaire, Sint Eustatius and Saba',
    BurkinaFaso = 'Burkina Faso',
    Bangladesh = 'Bangladesh',
    Bulgaria = 'Bulgaria',
    Bahrain = 'Bahrain',
    Bahamas = 'Bahamas',
    BosniaAndHerzegovina = 'Bosnia and Herzegovina',
    SaintBarthlemy = 'Saint Barthélemy',
    Belarus = 'Belarus',
    Belize = 'Belize',
    Bermuda = 'Bermuda',
    BoliviaPlurinationalStateOf = 'Bolivia, Plurinational State of',
    Brazil = 'Brazil',
    Barbados = 'Barbados',
    BruneiDarussalam = 'Brunei Darussalam',
    Bhutan = 'Bhutan',
    BouvetIsland = 'Bouvet Island',
    Botswana = 'Botswana',
    CentralAfricanRepublic = 'Central African Republic',
    Canada = 'Canada',
    CocosKeelingIslands = 'Cocos (Keeling) Islands',
    Switzerland = 'Switzerland',
    Chile = 'Chile',
    China = 'China',
    CteDIvoire = 'Côte d\'Ivoire',
    Cameroon = 'Cameroon',
    CongoTheDemocraticRepublicOfThe = 'Congo, The Democratic Republic of the',
    Congo = 'Congo',
    CookIslands = 'Cook Islands',
    Colombia = 'Colombia',
    Comoros = 'Comoros',
    CaboVerde = 'Cabo Verde',
    CostaRica = 'Costa Rica',
    Cuba = 'Cuba',
    Curaao = 'Curaçao',
    ChristmasIsland = 'Christmas Island',
    CaymanIslands = 'Cayman Islands',
    Cyprus = 'Cyprus',
    Czechia = 'Czechia',
    Germany = 'Germany',
    Djibouti = 'Djibouti',
    Dominica = 'Dominica',
    Denmark = 'Denmark',
    DominicanRepublic = 'Dominican Republic',
    Algeria = 'Algeria',
    Ecuador = 'Ecuador',
    Egypt = 'Egypt',
    Eritrea = 'Eritrea',
    WesternSahara = 'Western Sahara',
    Spain = 'Spain',
    Estonia = 'Estonia',
    Ethiopia = 'Ethiopia',
    Finland = 'Finland',
    Fiji = 'Fiji',
    FalklandIslandsMalvinas = 'Falkland Islands (Malvinas)',
    France = 'France',
    FaroeIslands = 'Faroe Islands',
    MicronesiaFederatedStatesOf = 'Micronesia, Federated States of',
    Gabon = 'Gabon',
    UnitedKingdom = 'United Kingdom',
    Georgia = 'Georgia',
    Guernsey = 'Guernsey',
    Ghana = 'Ghana',
    Gibraltar = 'Gibraltar',
    Guinea = 'Guinea',
    Guadeloupe = 'Guadeloupe',
    Gambia = 'Gambia',
    GuineaBissau = 'Guinea-Bissau',
    EquatorialGuinea = 'Equatorial Guinea',
    Greece = 'Greece',
    Grenada = 'Grenada',
    Greenland = 'Greenland',
    Guatemala = 'Guatemala',
    FrenchGuiana = 'French Guiana',
    Guam = 'Guam',
    Guyana = 'Guyana',
    HongKong = 'Hong Kong',
    HeardIslandAndMcDonaldIslands = 'Heard Island and McDonald Islands',
    Honduras = 'Honduras',
    Croatia = 'Croatia',
    Haiti = 'Haiti',
    Hungary = 'Hungary',
    Indonesia = 'Indonesia',
    IsleOfMan = 'Isle of Man',
    India = 'India',
    BritishIndianOceanTerritory = 'British Indian Ocean Territory',
    Ireland = 'Ireland',
    IranIslamicRepublicOf = 'Iran, Islamic Republic of',
    Iraq = 'Iraq',
    Iceland = 'Iceland',
    Israel = 'Israel',
    Italy = 'Italy',
    Jamaica = 'Jamaica',
    Jersey = 'Jersey',
    Jordan = 'Jordan',
    Japan = 'Japan',
    Kazakhstan = 'Kazakhstan',
    Kenya = 'Kenya',
    Kyrgyzstan = 'Kyrgyzstan',
    Cambodia = 'Cambodia',
    Kiribati = 'Kiribati',
    SaintKittsAndNevis = 'Saint Kitts and Nevis',
    KoreaRepublicOf = 'Korea, Republic of',
    Kuwait = 'Kuwait',
    LaoPeoplesDemocraticRepublic = 'Lao People\'s Democratic Republic',
    Lebanon = 'Lebanon',
    Liberia = 'Liberia',
    Libya = 'Libya',
    SaintLucia = 'Saint Lucia',
    Liechtenstein = 'Liechtenstein',
    SriLanka = 'Sri Lanka',
    Lesotho = 'Lesotho',
    Lithuania = 'Lithuania',
    Luxembourg = 'Luxembourg',
    Latvia = 'Latvia',
    Macao = 'Macao',
    SaintMartinFrenchPart = 'Saint Martin (French part)',
    Morocco = 'Morocco',
    Monaco = 'Monaco',
    MoldovaRepublicOf = 'Moldova, Republic of',
    Madagascar = 'Madagascar',
    Maldives = 'Maldives',
    Mexico = 'Mexico',
    MarshallIslands = 'Marshall Islands',
    NorthMacedonia = 'North Macedonia',
    Mali = 'Mali',
    Malta = 'Malta',
    Myanmar = 'Myanmar',
    Montenegro = 'Montenegro',
    Mongolia = 'Mongolia',
    NorthernMarianaIslands = 'Northern Mariana Islands',
    Mozambique = 'Mozambique',
    Mauritania = 'Mauritania',
    Montserrat = 'Montserrat',
    Martinique = 'Martinique',
    Mauritius = 'Mauritius',
    Malawi = 'Malawi',
    Malaysia = 'Malaysia',
    Mayotte = 'Mayotte',
    Namibia = 'Namibia',
    NewCaledonia = 'New Caledonia',
    Niger = 'Niger',
    NorfolkIsland = 'Norfolk Island',
    Nigeria = 'Nigeria',
    Nicaragua = 'Nicaragua',
    Niue = 'Niue',
    Netherlands = 'Netherlands',
    Norway = 'Norway',
    Nepal = 'Nepal',
    Nauru = 'Nauru',
    NewZealand = 'New Zealand',
    Oman = 'Oman',
    Pakistan = 'Pakistan',
    Panama = 'Panama',
    Pitcairn = 'Pitcairn',
    Peru = 'Peru',
    Philippines = 'Philippines',
    Palau = 'Palau',
    PapuaNewGuinea = 'Papua New Guinea',
    Poland = 'Poland',
    PuertoRico = 'Puerto Rico',
    KoreaDemocraticPeoplesRepublicOf = 'Korea, Democratic People\'s Republic of',
    Portugal = 'Portugal',
    Paraguay = 'Paraguay',
    PalestineStateOf = 'Palestine, State of',
    FrenchPolynesia = 'French Polynesia',
    Qatar = 'Qatar',
    Runion = 'Réunion',
    Romania = 'Romania',
    RussianFederation = 'Russian Federation',
    Rwanda = 'Rwanda',
    SaudiArabia = 'Saudi Arabia',
    Sudan = 'Sudan',
    Senegal = 'Senegal',
    Singapore = 'Singapore',
    SouthGeorgiaAndTheSouthSandwichIslands = 'South Georgia and the South Sandwich Islands',
    SaintHelenaAscensionAndTristanDaCunha = 'Saint Helena, Ascension and Tristan da Cunha',
    SvalbardAndJanMayen = 'Svalbard and Jan Mayen',
    SolomonIslands = 'Solomon Islands',
    SierraLeone = 'Sierra Leone',
    ElSalvador = 'El Salvador',
    SanMarino = 'San Marino',
    Somalia = 'Somalia',
    SaintPierreAndMiquelon = 'Saint Pierre and Miquelon',
    Serbia = 'Serbia',
    SouthSudan = 'South Sudan',
    SaoTomeAndPrincipe = 'Sao Tome and Principe',
    Suriname = 'Suriname',
    Slovakia = 'Slovakia',
    Slovenia = 'Slovenia',
    Sweden = 'Sweden',
    Eswatini = 'Eswatini',
    SintMaartenDutchPart = 'Sint Maarten (Dutch part)',
    Seychelles = 'Seychelles',
    SyrianArabRepublic = 'Syrian Arab Republic',
    TurksAndCaicosIslands = 'Turks and Caicos Islands',
    Chad = 'Chad',
    Togo = 'Togo',
    Thailand = 'Thailand',
    Tajikistan = 'Tajikistan',
    Tokelau = 'Tokelau',
    Turkmenistan = 'Turkmenistan',
    TimorLeste = 'Timor-Leste',
    Tonga = 'Tonga',
    TrinidadAndTobago = 'Trinidad and Tobago',
    Tunisia = 'Tunisia',
    Turkey = 'Turkey',
    Tuvalu = 'Tuvalu',
    TaiwanProvinceOfChina = 'Taiwan, Province of China',
    TanzaniaUnitedRepublicOf = 'Tanzania, United Republic of',
    Uganda = 'Uganda',
    Ukraine = 'Ukraine',
    UnitedStatesMinorOutlyingIslands = 'United States Minor Outlying Islands',
    Uruguay = 'Uruguay',
    UnitedStates = 'United States',
    Uzbekistan = 'Uzbekistan',
    HolySeeVaticanCityState = 'Holy See (Vatican City State)',
    SaintVincentAndTheGrenadines = 'Saint Vincent and the Grenadines',
    VenezuelaBolivarianRepublicOf = 'Venezuela, Bolivarian Republic of',
    VirginIslandsBritish = 'Virgin Islands, British',
    VirginIslandsUS = 'Virgin Islands, U.S.',
    VietNam = 'Viet Nam',
    Vanuatu = 'Vanuatu',
    WallisAndFutuna = 'Wallis and Futuna',
    Samoa = 'Samoa',
    Yemen = 'Yemen',
    SouthAfrica = 'South Africa',
    Zambia = 'Zambia',
    Zimbabwe = 'Zimbabwe'
}

/**
 * 
 * @export
 * @interface GeometryCollection
 */
export interface GeometryCollection {
    /**
     * 
     * @type {GeometryType}
     * @memberof GeometryCollection
     */
    'type': GeometryType;
    /**
     * 
     * @type {Array<GeometryElement>}
     * @memberof GeometryCollection
     */
    'geometries': Array<GeometryElement>;
}
/**
 * 
 * @export
 * @interface GeometryElement
 */
export interface GeometryElement {
    /**
     * 
     * @type {Array<any>}
     * @memberof GeometryElement
     */
    'coordinates': Array<any>;
    /**
     * 
     * @type {GeometryType}
     * @memberof GeometryElement
     */
    'type': GeometryType;
}
/**
 * 
 * @export
 * @interface GeometryItem
 */
export interface GeometryItem {
    /**
     * 
     * @type {string}
     * @memberof GeometryItem
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof GeometryItem
     */
    'kind': Kind;
    /**
     * 
     * @type {number}
     * @memberof GeometryItem
     */
    'geometry_id': number;
    /**
     * 
     * @type {string}
     * @memberof GeometryItem
     */
    'geometry_type': string;
    /**
     * 
     * @type {GeometryElement | GeometryCollection}
     * @memberof GeometryItem
     */
    'geometry': GeometryElement | GeometryCollection;
    /**
     * 
     * @type {object}
     * @memberof GeometryItem
     */
    'geometry_name'?: object;
    /**
     * 
     * @type {object}
     * @memberof GeometryItem
     */
    'extra'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GeometryItem
     */
    'display'?: boolean;
}
/**
 * 
 * @export
 * @interface GeometryItemCollection
 */
export interface GeometryItemCollection {
    /**
     * 
     * @type {string}
     * @memberof GeometryItemCollection
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof GeometryItemCollection
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof GeometryItemCollection
     */
    'page_token': string;
    /**
     * 
     * @type {string}
     * @memberof GeometryItemCollection
     */
    'next_page_token': string;
    /**
     * 
     * @type {number}
     * @memberof GeometryItemCollection
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof GeometryItemCollection
     */
    'total_size': number;
    /**
     * 
     * @type {number}
     * @memberof GeometryItemCollection
     */
    'total_pages': number;
    /**
     * 
     * @type {Array<GeometryItem>}
     * @memberof GeometryItemCollection
     */
    'contents': Array<GeometryItem>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum GeometryType {
    Point = 'Point',
    MultiPoint = 'MultiPoint',
    LineString = 'LineString',
    Polygon = 'Polygon',
    MultiPolygon = 'MultiPolygon',
    GeometryCollection = 'GeometryCollection'
}

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface ImageUploadResponse
 */
export interface ImageUploadResponse {
    /**
     * 
     * @type {string}
     * @memberof ImageUploadResponse
     */
    'file_path': string;
}
/**
 * 
 * @export
 * @interface Introduction
 */
export interface Introduction {
    /**
     * 
     * @type {string}
     * @memberof Introduction
     */
    'introduction_name'?: string;
    /**
     * 
     * @type {object}
     * @memberof Introduction
     */
    'introduction'?: object;
    /**
     * 
     * @type {Language}
     * @memberof Introduction
     */
    'language'?: Language;
    /**
     * 
     * @type {number}
     * @memberof Introduction
     */
    'artwork_id': number;
    /**
     * 
     * @type {string}
     * @memberof Introduction
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof Introduction
     */
    'kind': Kind;
    /**
     * 
     * @type {number}
     * @memberof Introduction
     */
    'introduction_id': number;
    /**
     * 
     * @type {string}
     * @memberof Introduction
     */
    'series': string;
    /**
     * 
     * @type {string}
     * @memberof Introduction
     */
    'artwork': string;
}
/**
 * 
 * @export
 * @interface IntroductionCollection
 */
export interface IntroductionCollection {
    /**
     * 
     * @type {string}
     * @memberof IntroductionCollection
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof IntroductionCollection
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof IntroductionCollection
     */
    'page_token': string;
    /**
     * 
     * @type {string}
     * @memberof IntroductionCollection
     */
    'next_page_token': string;
    /**
     * 
     * @type {number}
     * @memberof IntroductionCollection
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof IntroductionCollection
     */
    'total_size': number;
    /**
     * 
     * @type {number}
     * @memberof IntroductionCollection
     */
    'total_pages': number;
    /**
     * 
     * @type {Array<Introduction>}
     * @memberof IntroductionCollection
     */
    'contents': Array<Introduction>;
}
/**
 * 
 * @export
 * @interface IntroductionCreate
 */
export interface IntroductionCreate {
    /**
     * 
     * @type {string}
     * @memberof IntroductionCreate
     */
    'introduction_name'?: string;
    /**
     * 
     * @type {object}
     * @memberof IntroductionCreate
     */
    'introduction'?: object;
    /**
     * 
     * @type {Language}
     * @memberof IntroductionCreate
     */
    'language'?: Language;
    /**
     * 
     * @type {number}
     * @memberof IntroductionCreate
     */
    'artwork_id': number;
}
/**
 * 
 * @export
 * @interface IntroductionPatch
 */
export interface IntroductionPatch {
    /**
     * 
     * @type {string}
     * @memberof IntroductionPatch
     */
    'introduction_name'?: string;
    /**
     * 
     * @type {object}
     * @memberof IntroductionPatch
     */
    'introduction'?: object;
    /**
     * 
     * @type {Language}
     * @memberof IntroductionPatch
     */
    'language'?: Language;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ItemOrder {
    Rate = 'rate',
    RateBackwards = 'rate_backwards'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum Kind {
    Activity = 'activity',
    Artwork = 'artwork',
    Collection = 'collection',
    Geometry = 'geometry',
    Introduction = 'introduction',
    Landmark = 'landmark',
    Series = 'series',
    User = 'user'
}

/**
 * 
 * @export
 * @interface Landmark
 */
export interface Landmark {
    /**
     * 
     * @type {string}
     * @memberof Landmark
     */
    'cover_image'?: string;
    /**
     * 
     * @type {object}
     * @memberof Landmark
     */
    'description'?: object;
    /**
     * 
     * @type {object}
     * @memberof Landmark
     */
    'extra'?: object;
    /**
     * 
     * @type {GeometryElement | GeometryCollection}
     * @memberof Landmark
     */
    'geometry'?: GeometryElement | GeometryCollection;
    /**
     * 
     * @type {object}
     * @memberof Landmark
     */
    'landmark_name': object;
    /**
     * 
     * @type {Country}
     * @memberof Landmark
     */
    'country': Country;
    /**
     * 
     * @type {string}
     * @memberof Landmark
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Landmark
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof Landmark
     */
    'kind': Kind;
    /**
     * 
     * @type {number}
     * @memberof Landmark
     */
    'landmark_id': number;
    /**
     * 
     * @type {string}
     * @memberof Landmark
     */
    'artworks': string;
}
/**
 * 
 * @export
 * @interface LandmarkCollection
 */
export interface LandmarkCollection {
    /**
     * 
     * @type {string}
     * @memberof LandmarkCollection
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof LandmarkCollection
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof LandmarkCollection
     */
    'page_token': string;
    /**
     * 
     * @type {string}
     * @memberof LandmarkCollection
     */
    'next_page_token': string;
    /**
     * 
     * @type {number}
     * @memberof LandmarkCollection
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof LandmarkCollection
     */
    'total_size': number;
    /**
     * 
     * @type {number}
     * @memberof LandmarkCollection
     */
    'total_pages': number;
    /**
     * 
     * @type {Array<Landmark>}
     * @memberof LandmarkCollection
     */
    'contents': Array<Landmark>;
}
/**
 * 
 * @export
 * @interface LandmarkCreate
 */
export interface LandmarkCreate {
    /**
     * 
     * @type {string}
     * @memberof LandmarkCreate
     */
    'cover_image'?: string;
    /**
     * 
     * @type {object}
     * @memberof LandmarkCreate
     */
    'description'?: object;
    /**
     * 
     * @type {object}
     * @memberof LandmarkCreate
     */
    'extra'?: object;
    /**
     * 
     * @type {GeometryElement | GeometryCollection}
     * @memberof LandmarkCreate
     */
    'geometry'?: GeometryElement | GeometryCollection;
    /**
     * 
     * @type {object}
     * @memberof LandmarkCreate
     */
    'landmark_name': object;
    /**
     * 
     * @type {Country}
     * @memberof LandmarkCreate
     */
    'country': Country;
    /**
     * 
     * @type {string}
     * @memberof LandmarkCreate
     */
    'city': string;
}
/**
 * 
 * @export
 * @interface LandmarkPatch
 */
export interface LandmarkPatch {
    /**
     * 
     * @type {string}
     * @memberof LandmarkPatch
     */
    'cover_image'?: string;
    /**
     * 
     * @type {object}
     * @memberof LandmarkPatch
     */
    'description'?: object;
    /**
     * 
     * @type {object}
     * @memberof LandmarkPatch
     */
    'extra'?: object;
    /**
     * 
     * @type {GeometryElement | GeometryCollection}
     * @memberof LandmarkPatch
     */
    'geometry'?: GeometryElement | GeometryCollection;
    /**
     * 
     * @type {object}
     * @memberof LandmarkPatch
     */
    'landmark_name'?: object;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum Language {
    Cn = 'cn',
    En = 'en',
    Fr = 'fr'
}

/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'token_type'?: LoginResponseTokenTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LoginResponseTokenTypeEnum {
    Bearer = 'bearer'
}

/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'old_password': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface ResetPasswordResponse
 */
export interface ResetPasswordResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ResetPasswordResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface Root
 */
export interface Root {
    /**
     * 
     * @type {string}
     * @memberof Root
     */
    'users': string;
    /**
     * 
     * @type {string}
     * @memberof Root
     */
    'landmarks': string;
    /**
     * 
     * @type {string}
     * @memberof Root
     */
    'artworks': string;
    /**
     * 
     * @type {string}
     * @memberof Root
     */
    'series': string;
    /**
     * 
     * @type {string}
     * @memberof Root
     */
    'introductions': string;
}
/**
 * 
 * @export
 * @interface Series
 */
export interface Series {
    /**
     * 
     * @type {Language}
     * @memberof Series
     */
    'language'?: Language;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'series_name': string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'cover_image': string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof Series
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof Series
     */
    'kind': Kind;
    /**
     * 
     * @type {number}
     * @memberof Series
     */
    'series_id': number;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'landmark': string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'author': string;
}
/**
 * 
 * @export
 * @interface SeriesCollection
 */
export interface SeriesCollection {
    /**
     * 
     * @type {string}
     * @memberof SeriesCollection
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof SeriesCollection
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof SeriesCollection
     */
    'page_token': string;
    /**
     * 
     * @type {string}
     * @memberof SeriesCollection
     */
    'next_page_token': string;
    /**
     * 
     * @type {number}
     * @memberof SeriesCollection
     */
    'page_size': number;
    /**
     * 
     * @type {number}
     * @memberof SeriesCollection
     */
    'total_size': number;
    /**
     * 
     * @type {number}
     * @memberof SeriesCollection
     */
    'total_pages': number;
    /**
     * 
     * @type {Array<Series>}
     * @memberof SeriesCollection
     */
    'contents': Array<Series>;
}
/**
 * 
 * @export
 * @interface SeriesCreate
 */
export interface SeriesCreate {
    /**
     * 
     * @type {Language}
     * @memberof SeriesCreate
     */
    'language'?: Language;
    /**
     * 
     * @type {string}
     * @memberof SeriesCreate
     */
    'series_name': string;
    /**
     * 
     * @type {string}
     * @memberof SeriesCreate
     */
    'cover_image': string;
    /**
     * 
     * @type {string}
     * @memberof SeriesCreate
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof SeriesCreate
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface SeriesPatch
 */
export interface SeriesPatch {
    /**
     * 
     * @type {Language}
     * @memberof SeriesPatch
     */
    'language'?: Language;
    /**
     * 
     * @type {string}
     * @memberof SeriesPatch
     */
    'series_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SeriesPatch
     */
    'cover_image'?: string;
    /**
     * 
     * @type {string}
     * @memberof SeriesPatch
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof SeriesPatch
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name'?: string;
    /**
     * 
     * @type {Language}
     * @memberof User
     */
    'language'?: Language;
    /**
     * 
     * @type {UserRole}
     * @memberof User
     */
    'role'?: UserRole;
    /**
     * 
     * @type {object}
     * @memberof User
     */
    'extras'?: object;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'user_email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'self_link': string;
    /**
     * 
     * @type {Kind}
     * @memberof User
     */
    'kind': Kind;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'date_joined'?: string;
}
/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'last_name'?: string;
    /**
     * 
     * @type {Language}
     * @memberof UserCreate
     */
    'language'?: Language;
    /**
     * 
     * @type {UserRole}
     * @memberof UserCreate
     */
    'role'?: UserRole;
    /**
     * 
     * @type {object}
     * @memberof UserCreate
     */
    'extras'?: object;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'user_email': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'password': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum UserRole {
    Admin = 'admin',
    Editor = 'editor',
    Visitor = 'visitor'
}

/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    'loc': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}

/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Activities Activity Id
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivitiesActivityIdActivitiesActivityIdDelete: async (activityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('deleteActivitiesActivityIdActivitiesActivityIdDelete', 'activityId', activityId)
            const localVarPath = `/activities/{activity_id}/`
                .replace(`{${"activity_id"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Activities
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesActivitiesGet: async (pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activities/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Activities Activity Id
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesActivityIdActivitiesActivityIdGet: async (activityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getActivitiesActivityIdActivitiesActivityIdGet', 'activityId', activityId)
            const localVarPath = `/activities/{activity_id}/`
                .replace(`{${"activity_id"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Activities Activity Id
         * @param {number} activityId 
         * @param {ActivityPatch} activityPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchActivitiesActivityIdActivitiesActivityIdPatch: async (activityId: number, activityPatch: ActivityPatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('patchActivitiesActivityIdActivitiesActivityIdPatch', 'activityId', activityId)
            // verify required parameter 'activityPatch' is not null or undefined
            assertParamExists('patchActivitiesActivityIdActivitiesActivityIdPatch', 'activityPatch', activityPatch)
            const localVarPath = `/activities/{activity_id}/`
                .replace(`{${"activity_id"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Activities
         * @param {ActivityCreate} activityCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivitiesActivitiesPost: async (activityCreate: ActivityCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityCreate' is not null or undefined
            assertParamExists('postActivitiesActivitiesPost', 'activityCreate', activityCreate)
            const localVarPath = `/activities/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Activities Keywords
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivitiesKeywordsSearchActivitiesKeywordsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/activities/keywords/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Activities
         * @param {string} [q] 
         * @param {string} [fields] 
         * @param {string} [date] 
         * @param {number} [lat] 
         * @param {number} [lon] 
         * @param {number} [range] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivitiesSearchActivitiesGet: async (q?: string, fields?: string, date?: string, lat?: number, lon?: number, range?: number, pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/activities/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lon !== undefined) {
                localVarQueryParameter['lon'] = lon;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Activities Activity Id
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivitiesActivityIdActivitiesActivityIdDelete(activityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivitiesActivityIdActivitiesActivityIdDelete(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Activities
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivitiesActivitiesGet(pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivitiesActivitiesGet(pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Activities Activity Id
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivitiesActivityIdActivitiesActivityIdGet(activityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivitiesActivityIdActivitiesActivityIdGet(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Activities Activity Id
         * @param {number} activityId 
         * @param {ActivityPatch} activityPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchActivitiesActivityIdActivitiesActivityIdPatch(activityId: number, activityPatch: ActivityPatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchActivitiesActivityIdActivitiesActivityIdPatch(activityId, activityPatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post Activities
         * @param {ActivityCreate} activityCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActivitiesActivitiesPost(activityCreate: ActivityCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActivitiesActivitiesPost(activityCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search Activities Keywords
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchActivitiesKeywordsSearchActivitiesKeywordsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityKeywords>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchActivitiesKeywordsSearchActivitiesKeywordsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search Activities
         * @param {string} [q] 
         * @param {string} [fields] 
         * @param {string} [date] 
         * @param {number} [lat] 
         * @param {number} [lon] 
         * @param {number} [range] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchActivitiesSearchActivitiesGet(q?: string, fields?: string, date?: string, lat?: number, lon?: number, range?: number, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityCollection | ActivityBriefCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchActivitiesSearchActivitiesGet(q, fields, date, lat, lon, range, pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Activities Activity Id
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivitiesActivityIdActivitiesActivityIdDelete(activityId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteActivitiesActivityIdActivitiesActivityIdDelete(activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Activities
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesActivitiesGet(pageToken?: string, pageSize?: number, options?: any): AxiosPromise<ActivityCollection> {
            return localVarFp.getActivitiesActivitiesGet(pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Activities Activity Id
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesActivityIdActivitiesActivityIdGet(activityId: number, options?: any): AxiosPromise<Activity> {
            return localVarFp.getActivitiesActivityIdActivitiesActivityIdGet(activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Activities Activity Id
         * @param {number} activityId 
         * @param {ActivityPatch} activityPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchActivitiesActivityIdActivitiesActivityIdPatch(activityId: number, activityPatch: ActivityPatch, options?: any): AxiosPromise<Activity> {
            return localVarFp.patchActivitiesActivityIdActivitiesActivityIdPatch(activityId, activityPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Activities
         * @param {ActivityCreate} activityCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivitiesActivitiesPost(activityCreate: ActivityCreate, options?: any): AxiosPromise<Activity> {
            return localVarFp.postActivitiesActivitiesPost(activityCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Activities Keywords
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivitiesKeywordsSearchActivitiesKeywordsGet(options?: any): AxiosPromise<ActivityKeywords> {
            return localVarFp.searchActivitiesKeywordsSearchActivitiesKeywordsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Activities
         * @param {string} [q] 
         * @param {string} [fields] 
         * @param {string} [date] 
         * @param {number} [lat] 
         * @param {number} [lon] 
         * @param {number} [range] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivitiesSearchActivitiesGet(q?: string, fields?: string, date?: string, lat?: number, lon?: number, range?: number, pageToken?: string, pageSize?: number, options?: any): AxiosPromise<ActivityCollection | ActivityBriefCollection> {
            return localVarFp.searchActivitiesSearchActivitiesGet(q, fields, date, lat, lon, range, pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @summary Delete Activities Activity Id
     * @param {number} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public deleteActivitiesActivityIdActivitiesActivityIdDelete(activityId: number, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).deleteActivitiesActivityIdActivitiesActivityIdDelete(activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Activities
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getActivitiesActivitiesGet(pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getActivitiesActivitiesGet(pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Activities Activity Id
     * @param {number} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getActivitiesActivityIdActivitiesActivityIdGet(activityId: number, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getActivitiesActivityIdActivitiesActivityIdGet(activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Activities Activity Id
     * @param {number} activityId 
     * @param {ActivityPatch} activityPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public patchActivitiesActivityIdActivitiesActivityIdPatch(activityId: number, activityPatch: ActivityPatch, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).patchActivitiesActivityIdActivitiesActivityIdPatch(activityId, activityPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Activities
     * @param {ActivityCreate} activityCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public postActivitiesActivitiesPost(activityCreate: ActivityCreate, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).postActivitiesActivitiesPost(activityCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Activities Keywords
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public searchActivitiesKeywordsSearchActivitiesKeywordsGet(options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).searchActivitiesKeywordsSearchActivitiesKeywordsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Activities
     * @param {string} [q] 
     * @param {string} [fields] 
     * @param {string} [date] 
     * @param {number} [lat] 
     * @param {number} [lon] 
     * @param {number} [range] 
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public searchActivitiesSearchActivitiesGet(q?: string, fields?: string, date?: string, lat?: number, lon?: number, range?: number, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).searchActivitiesSearchActivitiesGet(q, fields, date, lat, lon, range, pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ArtworksApi - axios parameter creator
 * @export
 */
export const ArtworksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Artworks Artwork Id
         * @param {number} artworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArtworksArtworkIdArtworksArtworkIdDelete: async (artworkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'artworkId' is not null or undefined
            assertParamExists('deleteArtworksArtworkIdArtworksArtworkIdDelete', 'artworkId', artworkId)
            const localVarPath = `/artworks/{artwork_id}/`
                .replace(`{${"artwork_id"}}`, encodeURIComponent(String(artworkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Artworks Artwork Id
         * @param {number} artworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtworksArtworkIdArtworksArtworkIdGet: async (artworkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'artworkId' is not null or undefined
            assertParamExists('getArtworksArtworkIdArtworksArtworkIdGet', 'artworkId', artworkId)
            const localVarPath = `/artworks/{artwork_id}/`
                .replace(`{${"artwork_id"}}`, encodeURIComponent(String(artworkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Artworks
         * @param {number} landmarkId 
         * @param {ItemOrder} [order] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtworksLandmarksLandmarkIdArtworksGet: async (landmarkId: number, order?: ItemOrder, pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landmarkId' is not null or undefined
            assertParamExists('getArtworksLandmarksLandmarkIdArtworksGet', 'landmarkId', landmarkId)
            const localVarPath = `/landmarks/{landmark_id}/artworks/`
                .replace(`{${"landmark_id"}}`, encodeURIComponent(String(landmarkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Artworks Artwork Id
         * @param {number} artworkId 
         * @param {ArtworkPatch} artworkPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchArtworksArtworkIdArtworksArtworkIdPatch: async (artworkId: number, artworkPatch: ArtworkPatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'artworkId' is not null or undefined
            assertParamExists('patchArtworksArtworkIdArtworksArtworkIdPatch', 'artworkId', artworkId)
            // verify required parameter 'artworkPatch' is not null or undefined
            assertParamExists('patchArtworksArtworkIdArtworksArtworkIdPatch', 'artworkPatch', artworkPatch)
            const localVarPath = `/artworks/{artwork_id}/`
                .replace(`{${"artwork_id"}}`, encodeURIComponent(String(artworkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(artworkPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Artworks
         * @param {number} landmarkId 
         * @param {ArtworkCreate} artworkCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postArtworksLandmarksLandmarkIdArtworksPost: async (landmarkId: number, artworkCreate: ArtworkCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landmarkId' is not null or undefined
            assertParamExists('postArtworksLandmarksLandmarkIdArtworksPost', 'landmarkId', landmarkId)
            // verify required parameter 'artworkCreate' is not null or undefined
            assertParamExists('postArtworksLandmarksLandmarkIdArtworksPost', 'artworkCreate', artworkCreate)
            const localVarPath = `/landmarks/{landmark_id}/artworks/`
                .replace(`{${"landmark_id"}}`, encodeURIComponent(String(landmarkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(artworkCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Artworks
         * @param {string} q 
         * @param {ItemOrder} [order] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtworksSearchArtworksGet: async (q: string, order?: ItemOrder, pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('searchArtworksSearchArtworksGet', 'q', q)
            const localVarPath = `/search/artworks/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArtworksApi - functional programming interface
 * @export
 */
export const ArtworksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArtworksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Artworks Artwork Id
         * @param {number} artworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteArtworksArtworkIdArtworksArtworkIdDelete(artworkId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArtworksArtworkIdArtworksArtworkIdDelete(artworkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Artworks Artwork Id
         * @param {number} artworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArtworksArtworkIdArtworksArtworkIdGet(artworkId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Artwork>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArtworksArtworkIdArtworksArtworkIdGet(artworkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Artworks
         * @param {number} landmarkId 
         * @param {ItemOrder} [order] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArtworksLandmarksLandmarkIdArtworksGet(landmarkId: number, order?: ItemOrder, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtworkCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArtworksLandmarksLandmarkIdArtworksGet(landmarkId, order, pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Artworks Artwork Id
         * @param {number} artworkId 
         * @param {ArtworkPatch} artworkPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchArtworksArtworkIdArtworksArtworkIdPatch(artworkId: number, artworkPatch: ArtworkPatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Artwork>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchArtworksArtworkIdArtworksArtworkIdPatch(artworkId, artworkPatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post Artworks
         * @param {number} landmarkId 
         * @param {ArtworkCreate} artworkCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postArtworksLandmarksLandmarkIdArtworksPost(landmarkId: number, artworkCreate: ArtworkCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Artwork>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postArtworksLandmarksLandmarkIdArtworksPost(landmarkId, artworkCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search Artworks
         * @param {string} q 
         * @param {ItemOrder} [order] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchArtworksSearchArtworksGet(q: string, order?: ItemOrder, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtworkCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchArtworksSearchArtworksGet(q, order, pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArtworksApi - factory interface
 * @export
 */
export const ArtworksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArtworksApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Artworks Artwork Id
         * @param {number} artworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArtworksArtworkIdArtworksArtworkIdDelete(artworkId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteArtworksArtworkIdArtworksArtworkIdDelete(artworkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Artworks Artwork Id
         * @param {number} artworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtworksArtworkIdArtworksArtworkIdGet(artworkId: number, options?: any): AxiosPromise<Artwork> {
            return localVarFp.getArtworksArtworkIdArtworksArtworkIdGet(artworkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Artworks
         * @param {number} landmarkId 
         * @param {ItemOrder} [order] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtworksLandmarksLandmarkIdArtworksGet(landmarkId: number, order?: ItemOrder, pageToken?: string, pageSize?: number, options?: any): AxiosPromise<ArtworkCollection> {
            return localVarFp.getArtworksLandmarksLandmarkIdArtworksGet(landmarkId, order, pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Artworks Artwork Id
         * @param {number} artworkId 
         * @param {ArtworkPatch} artworkPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchArtworksArtworkIdArtworksArtworkIdPatch(artworkId: number, artworkPatch: ArtworkPatch, options?: any): AxiosPromise<Artwork> {
            return localVarFp.patchArtworksArtworkIdArtworksArtworkIdPatch(artworkId, artworkPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Artworks
         * @param {number} landmarkId 
         * @param {ArtworkCreate} artworkCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postArtworksLandmarksLandmarkIdArtworksPost(landmarkId: number, artworkCreate: ArtworkCreate, options?: any): AxiosPromise<Artwork> {
            return localVarFp.postArtworksLandmarksLandmarkIdArtworksPost(landmarkId, artworkCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Artworks
         * @param {string} q 
         * @param {ItemOrder} [order] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArtworksSearchArtworksGet(q: string, order?: ItemOrder, pageToken?: string, pageSize?: number, options?: any): AxiosPromise<ArtworkCollection> {
            return localVarFp.searchArtworksSearchArtworksGet(q, order, pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArtworksApi - object-oriented interface
 * @export
 * @class ArtworksApi
 * @extends {BaseAPI}
 */
export class ArtworksApi extends BaseAPI {
    /**
     * 
     * @summary Delete Artworks Artwork Id
     * @param {number} artworkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworksApi
     */
    public deleteArtworksArtworkIdArtworksArtworkIdDelete(artworkId: number, options?: AxiosRequestConfig) {
        return ArtworksApiFp(this.configuration).deleteArtworksArtworkIdArtworksArtworkIdDelete(artworkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Artworks Artwork Id
     * @param {number} artworkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworksApi
     */
    public getArtworksArtworkIdArtworksArtworkIdGet(artworkId: number, options?: AxiosRequestConfig) {
        return ArtworksApiFp(this.configuration).getArtworksArtworkIdArtworksArtworkIdGet(artworkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Artworks
     * @param {number} landmarkId 
     * @param {ItemOrder} [order] 
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworksApi
     */
    public getArtworksLandmarksLandmarkIdArtworksGet(landmarkId: number, order?: ItemOrder, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return ArtworksApiFp(this.configuration).getArtworksLandmarksLandmarkIdArtworksGet(landmarkId, order, pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Artworks Artwork Id
     * @param {number} artworkId 
     * @param {ArtworkPatch} artworkPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworksApi
     */
    public patchArtworksArtworkIdArtworksArtworkIdPatch(artworkId: number, artworkPatch: ArtworkPatch, options?: AxiosRequestConfig) {
        return ArtworksApiFp(this.configuration).patchArtworksArtworkIdArtworksArtworkIdPatch(artworkId, artworkPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Artworks
     * @param {number} landmarkId 
     * @param {ArtworkCreate} artworkCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworksApi
     */
    public postArtworksLandmarksLandmarkIdArtworksPost(landmarkId: number, artworkCreate: ArtworkCreate, options?: AxiosRequestConfig) {
        return ArtworksApiFp(this.configuration).postArtworksLandmarksLandmarkIdArtworksPost(landmarkId, artworkCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Artworks
     * @param {string} q 
     * @param {ItemOrder} [order] 
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtworksApi
     */
    public searchArtworksSearchArtworksGet(q: string, order?: ItemOrder, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return ArtworksApiFp(this.configuration).searchArtworksSearchArtworksGet(q, order, pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeometryApi - axios parameter creator
 * @export
 */
export const GeometryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Geometries
         * @param {number} [lat] 
         * @param {number} [lon] 
         * @param {number} [range] 
         * @param {string} [geometryType] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeometriesGeometriesGet: async (lat?: number, lon?: number, range?: number, geometryType?: string, pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geometries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lon !== undefined) {
                localVarQueryParameter['lon'] = lon;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (geometryType !== undefined) {
                localVarQueryParameter['geometry_type'] = geometryType;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeometryApi - functional programming interface
 * @export
 */
export const GeometryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeometryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Geometries
         * @param {number} [lat] 
         * @param {number} [lon] 
         * @param {number} [range] 
         * @param {string} [geometryType] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeometriesGeometriesGet(lat?: number, lon?: number, range?: number, geometryType?: string, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeometryItemCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeometriesGeometriesGet(lat, lon, range, geometryType, pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeometryApi - factory interface
 * @export
 */
export const GeometryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeometryApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Geometries
         * @param {number} [lat] 
         * @param {number} [lon] 
         * @param {number} [range] 
         * @param {string} [geometryType] 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeometriesGeometriesGet(lat?: number, lon?: number, range?: number, geometryType?: string, pageToken?: string, pageSize?: number, options?: any): AxiosPromise<GeometryItemCollection> {
            return localVarFp.getGeometriesGeometriesGet(lat, lon, range, geometryType, pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeometryApi - object-oriented interface
 * @export
 * @class GeometryApi
 * @extends {BaseAPI}
 */
export class GeometryApi extends BaseAPI {
    /**
     * 
     * @summary Get Geometries
     * @param {number} [lat] 
     * @param {number} [lon] 
     * @param {number} [range] 
     * @param {string} [geometryType] 
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public getGeometriesGeometriesGet(lat?: number, lon?: number, range?: number, geometryType?: string, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).getGeometriesGeometriesGet(lat, lon, range, geometryType, pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Image
         * @param {string} dir 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageImagesDirFilePathDelete: async (dir: string, filePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dir' is not null or undefined
            assertParamExists('deleteImageImagesDirFilePathDelete', 'dir', dir)
            // verify required parameter 'filePath' is not null or undefined
            assertParamExists('deleteImageImagesDirFilePathDelete', 'filePath', filePath)
            const localVarPath = `/images/{dir}/{file_path}/`
                .replace(`{${"dir"}}`, encodeURIComponent(String(dir)))
                .replace(`{${"file_path"}}`, encodeURIComponent(String(filePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detect Image
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectImageDetectPost: async (image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('detectImageDetectPost', 'image', image)
            const localVarPath = `/detect/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Image
         * @param {string} dir 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageImagesDirFilePathGet: async (dir: string, filePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dir' is not null or undefined
            assertParamExists('getImageImagesDirFilePathGet', 'dir', dir)
            // verify required parameter 'filePath' is not null or undefined
            assertParamExists('getImageImagesDirFilePathGet', 'filePath', filePath)
            const localVarPath = `/images/{dir}/{file_path}/`
                .replace(`{${"dir"}}`, encodeURIComponent(String(dir)))
                .replace(`{${"file_path"}}`, encodeURIComponent(String(filePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Image
         * @param {string} dir 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImageImagesDirPost: async (dir: string, image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dir' is not null or undefined
            assertParamExists('postImageImagesDirPost', 'dir', dir)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('postImageImagesDirPost', 'image', image)
            const localVarPath = `/images/{dir}/`
                .replace(`{${"dir"}}`, encodeURIComponent(String(dir)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Image
         * @param {string} dir 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImageImagesDirFilePathDelete(dir: string, filePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImageImagesDirFilePathDelete(dir, filePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Detect Image
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detectImageDetectPost(image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Artwork>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detectImageDetectPost(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Image
         * @param {string} dir 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageImagesDirFilePathGet(dir: string, filePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImageImagesDirFilePathGet(dir, filePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post Image
         * @param {string} dir 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postImageImagesDirPost(dir: string, image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postImageImagesDirPost(dir, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Image
         * @param {string} dir 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageImagesDirFilePathDelete(dir: string, filePath: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteImageImagesDirFilePathDelete(dir, filePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Detect Image
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectImageDetectPost(image: any, options?: any): AxiosPromise<Artwork> {
            return localVarFp.detectImageDetectPost(image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Image
         * @param {string} dir 
         * @param {string} filePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageImagesDirFilePathGet(dir: string, filePath: string, options?: any): AxiosPromise<any> {
            return localVarFp.getImageImagesDirFilePathGet(dir, filePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Image
         * @param {string} dir 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImageImagesDirPost(dir: string, image: any, options?: any): AxiosPromise<ImageUploadResponse> {
            return localVarFp.postImageImagesDirPost(dir, image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * 
     * @summary Delete Image
     * @param {string} dir 
     * @param {string} filePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public deleteImageImagesDirFilePathDelete(dir: string, filePath: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).deleteImageImagesDirFilePathDelete(dir, filePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Detect Image
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public detectImageDetectPost(image: any, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).detectImageDetectPost(image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Image
     * @param {string} dir 
     * @param {string} filePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public getImageImagesDirFilePathGet(dir: string, filePath: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).getImageImagesDirFilePathGet(dir, filePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Image
     * @param {string} dir 
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public postImageImagesDirPost(dir: string, image: any, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).postImageImagesDirPost(dir, image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntroductionsApi - axios parameter creator
 * @export
 */
export const IntroductionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Introductions Introductions Id
         * @param {number} introductionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntroductionsIntroductionsIdIntroductionsIntroductionIdDelete: async (introductionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'introductionId' is not null or undefined
            assertParamExists('deleteIntroductionsIntroductionsIdIntroductionsIntroductionIdDelete', 'introductionId', introductionId)
            const localVarPath = `/introductions/{introduction_id}/`
                .replace(`{${"introduction_id"}}`, encodeURIComponent(String(introductionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Introductions Introduction Id
         * @param {number} introductionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntroductionsIntroductionIdIntroductionsIntroductionIdGet: async (introductionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'introductionId' is not null or undefined
            assertParamExists('getIntroductionsIntroductionIdIntroductionsIntroductionIdGet', 'introductionId', introductionId)
            const localVarPath = `/introductions/{introduction_id}/`
                .replace(`{${"introduction_id"}}`, encodeURIComponent(String(introductionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Series Series Id Introductions
         * @param {number} seriesId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsGet: async (seriesId: number, pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('getSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsGet', 'seriesId', seriesId)
            const localVarPath = `/series/{series_id}/introductions/`
                .replace(`{${"series_id"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Introductions Introduction Id
         * @param {number} introductionId 
         * @param {IntroductionPatch} introductionPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchIntroductionsIntroductionIdIntroductionsIntroductionIdPatch: async (introductionId: number, introductionPatch: IntroductionPatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'introductionId' is not null or undefined
            assertParamExists('patchIntroductionsIntroductionIdIntroductionsIntroductionIdPatch', 'introductionId', introductionId)
            // verify required parameter 'introductionPatch' is not null or undefined
            assertParamExists('patchIntroductionsIntroductionIdIntroductionsIntroductionIdPatch', 'introductionPatch', introductionPatch)
            const localVarPath = `/introductions/{introduction_id}/`
                .replace(`{${"introduction_id"}}`, encodeURIComponent(String(introductionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(introductionPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Series Series Id Introductions
         * @param {number} seriesId 
         * @param {IntroductionCreate} introductionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsPost: async (seriesId: number, introductionCreate: IntroductionCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('postSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsPost', 'seriesId', seriesId)
            // verify required parameter 'introductionCreate' is not null or undefined
            assertParamExists('postSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsPost', 'introductionCreate', introductionCreate)
            const localVarPath = `/series/{series_id}/introductions/`
                .replace(`{${"series_id"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(introductionCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntroductionsApi - functional programming interface
 * @export
 */
export const IntroductionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntroductionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Introductions Introductions Id
         * @param {number} introductionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIntroductionsIntroductionsIdIntroductionsIntroductionIdDelete(introductionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIntroductionsIntroductionsIdIntroductionsIntroductionIdDelete(introductionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Introductions Introduction Id
         * @param {number} introductionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntroductionsIntroductionIdIntroductionsIntroductionIdGet(introductionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Introduction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntroductionsIntroductionIdIntroductionsIntroductionIdGet(introductionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Series Series Id Introductions
         * @param {number} seriesId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsGet(seriesId: number, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntroductionCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsGet(seriesId, pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Introductions Introduction Id
         * @param {number} introductionId 
         * @param {IntroductionPatch} introductionPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchIntroductionsIntroductionIdIntroductionsIntroductionIdPatch(introductionId: number, introductionPatch: IntroductionPatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Introduction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchIntroductionsIntroductionIdIntroductionsIntroductionIdPatch(introductionId, introductionPatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post Series Series Id Introductions
         * @param {number} seriesId 
         * @param {IntroductionCreate} introductionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsPost(seriesId: number, introductionCreate: IntroductionCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Introduction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsPost(seriesId, introductionCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntroductionsApi - factory interface
 * @export
 */
export const IntroductionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntroductionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Introductions Introductions Id
         * @param {number} introductionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntroductionsIntroductionsIdIntroductionsIntroductionIdDelete(introductionId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteIntroductionsIntroductionsIdIntroductionsIntroductionIdDelete(introductionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Introductions Introduction Id
         * @param {number} introductionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntroductionsIntroductionIdIntroductionsIntroductionIdGet(introductionId: number, options?: any): AxiosPromise<Introduction> {
            return localVarFp.getIntroductionsIntroductionIdIntroductionsIntroductionIdGet(introductionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Series Series Id Introductions
         * @param {number} seriesId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsGet(seriesId: number, pageToken?: string, pageSize?: number, options?: any): AxiosPromise<IntroductionCollection> {
            return localVarFp.getSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsGet(seriesId, pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Introductions Introduction Id
         * @param {number} introductionId 
         * @param {IntroductionPatch} introductionPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchIntroductionsIntroductionIdIntroductionsIntroductionIdPatch(introductionId: number, introductionPatch: IntroductionPatch, options?: any): AxiosPromise<Introduction> {
            return localVarFp.patchIntroductionsIntroductionIdIntroductionsIntroductionIdPatch(introductionId, introductionPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Series Series Id Introductions
         * @param {number} seriesId 
         * @param {IntroductionCreate} introductionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsPost(seriesId: number, introductionCreate: IntroductionCreate, options?: any): AxiosPromise<Introduction> {
            return localVarFp.postSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsPost(seriesId, introductionCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntroductionsApi - object-oriented interface
 * @export
 * @class IntroductionsApi
 * @extends {BaseAPI}
 */
export class IntroductionsApi extends BaseAPI {
    /**
     * 
     * @summary Delete Introductions Introductions Id
     * @param {number} introductionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntroductionsApi
     */
    public deleteIntroductionsIntroductionsIdIntroductionsIntroductionIdDelete(introductionId: number, options?: AxiosRequestConfig) {
        return IntroductionsApiFp(this.configuration).deleteIntroductionsIntroductionsIdIntroductionsIntroductionIdDelete(introductionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Introductions Introduction Id
     * @param {number} introductionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntroductionsApi
     */
    public getIntroductionsIntroductionIdIntroductionsIntroductionIdGet(introductionId: number, options?: AxiosRequestConfig) {
        return IntroductionsApiFp(this.configuration).getIntroductionsIntroductionIdIntroductionsIntroductionIdGet(introductionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Series Series Id Introductions
     * @param {number} seriesId 
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntroductionsApi
     */
    public getSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsGet(seriesId: number, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return IntroductionsApiFp(this.configuration).getSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsGet(seriesId, pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Introductions Introduction Id
     * @param {number} introductionId 
     * @param {IntroductionPatch} introductionPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntroductionsApi
     */
    public patchIntroductionsIntroductionIdIntroductionsIntroductionIdPatch(introductionId: number, introductionPatch: IntroductionPatch, options?: AxiosRequestConfig) {
        return IntroductionsApiFp(this.configuration).patchIntroductionsIntroductionIdIntroductionsIntroductionIdPatch(introductionId, introductionPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Series Series Id Introductions
     * @param {number} seriesId 
     * @param {IntroductionCreate} introductionCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntroductionsApi
     */
    public postSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsPost(seriesId: number, introductionCreate: IntroductionCreate, options?: AxiosRequestConfig) {
        return IntroductionsApiFp(this.configuration).postSeriesSeriesIdIntroductionsSeriesSeriesIdIntroductionsPost(seriesId, introductionCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LandmarksApi - axios parameter creator
 * @export
 */
export const LandmarksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Landmarks Landmark Id
         * @param {number} landmarkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLandmarksLandmarkIdLandmarksLandmarkIdDelete: async (landmarkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landmarkId' is not null or undefined
            assertParamExists('deleteLandmarksLandmarkIdLandmarksLandmarkIdDelete', 'landmarkId', landmarkId)
            const localVarPath = `/landmarks/{landmark_id}/`
                .replace(`{${"landmark_id"}}`, encodeURIComponent(String(landmarkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Landmarks Landmark Id
         * @param {number} landmarkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandmarksLandmarkIdLandmarksLandmarkIdGet: async (landmarkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landmarkId' is not null or undefined
            assertParamExists('getLandmarksLandmarkIdLandmarksLandmarkIdGet', 'landmarkId', landmarkId)
            const localVarPath = `/landmarks/{landmark_id}/`
                .replace(`{${"landmark_id"}}`, encodeURIComponent(String(landmarkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Landmarks
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandmarksLandmarksGet: async (pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/landmarks/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Landmarks Landmark Id
         * @param {number} landmarkId 
         * @param {LandmarkPatch} landmarkPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLandmarksLandmarkIdLandmarksLandmarkIdPatch: async (landmarkId: number, landmarkPatch: LandmarkPatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landmarkId' is not null or undefined
            assertParamExists('patchLandmarksLandmarkIdLandmarksLandmarkIdPatch', 'landmarkId', landmarkId)
            // verify required parameter 'landmarkPatch' is not null or undefined
            assertParamExists('patchLandmarksLandmarkIdLandmarksLandmarkIdPatch', 'landmarkPatch', landmarkPatch)
            const localVarPath = `/landmarks/{landmark_id}/`
                .replace(`{${"landmark_id"}}`, encodeURIComponent(String(landmarkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(landmarkPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Landmarks
         * @param {LandmarkCreate} landmarkCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLandmarksLandmarksPost: async (landmarkCreate: LandmarkCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landmarkCreate' is not null or undefined
            assertParamExists('postLandmarksLandmarksPost', 'landmarkCreate', landmarkCreate)
            const localVarPath = `/landmarks/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(landmarkCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LandmarksApi - functional programming interface
 * @export
 */
export const LandmarksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LandmarksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Landmarks Landmark Id
         * @param {number} landmarkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLandmarksLandmarkIdLandmarksLandmarkIdDelete(landmarkId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLandmarksLandmarkIdLandmarksLandmarkIdDelete(landmarkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Landmarks Landmark Id
         * @param {number} landmarkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLandmarksLandmarkIdLandmarksLandmarkIdGet(landmarkId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Landmark>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLandmarksLandmarkIdLandmarksLandmarkIdGet(landmarkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Landmarks
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLandmarksLandmarksGet(pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LandmarkCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLandmarksLandmarksGet(pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Landmarks Landmark Id
         * @param {number} landmarkId 
         * @param {LandmarkPatch} landmarkPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchLandmarksLandmarkIdLandmarksLandmarkIdPatch(landmarkId: number, landmarkPatch: LandmarkPatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Landmark>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchLandmarksLandmarkIdLandmarksLandmarkIdPatch(landmarkId, landmarkPatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post Landmarks
         * @param {LandmarkCreate} landmarkCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLandmarksLandmarksPost(landmarkCreate: LandmarkCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Landmark>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLandmarksLandmarksPost(landmarkCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LandmarksApi - factory interface
 * @export
 */
export const LandmarksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LandmarksApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Landmarks Landmark Id
         * @param {number} landmarkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLandmarksLandmarkIdLandmarksLandmarkIdDelete(landmarkId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteLandmarksLandmarkIdLandmarksLandmarkIdDelete(landmarkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Landmarks Landmark Id
         * @param {number} landmarkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandmarksLandmarkIdLandmarksLandmarkIdGet(landmarkId: number, options?: any): AxiosPromise<Landmark> {
            return localVarFp.getLandmarksLandmarkIdLandmarksLandmarkIdGet(landmarkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Landmarks
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandmarksLandmarksGet(pageToken?: string, pageSize?: number, options?: any): AxiosPromise<LandmarkCollection> {
            return localVarFp.getLandmarksLandmarksGet(pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Landmarks Landmark Id
         * @param {number} landmarkId 
         * @param {LandmarkPatch} landmarkPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLandmarksLandmarkIdLandmarksLandmarkIdPatch(landmarkId: number, landmarkPatch: LandmarkPatch, options?: any): AxiosPromise<Landmark> {
            return localVarFp.patchLandmarksLandmarkIdLandmarksLandmarkIdPatch(landmarkId, landmarkPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Landmarks
         * @param {LandmarkCreate} landmarkCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLandmarksLandmarksPost(landmarkCreate: LandmarkCreate, options?: any): AxiosPromise<Landmark> {
            return localVarFp.postLandmarksLandmarksPost(landmarkCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LandmarksApi - object-oriented interface
 * @export
 * @class LandmarksApi
 * @extends {BaseAPI}
 */
export class LandmarksApi extends BaseAPI {
    /**
     * 
     * @summary Delete Landmarks Landmark Id
     * @param {number} landmarkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandmarksApi
     */
    public deleteLandmarksLandmarkIdLandmarksLandmarkIdDelete(landmarkId: number, options?: AxiosRequestConfig) {
        return LandmarksApiFp(this.configuration).deleteLandmarksLandmarkIdLandmarksLandmarkIdDelete(landmarkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Landmarks Landmark Id
     * @param {number} landmarkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandmarksApi
     */
    public getLandmarksLandmarkIdLandmarksLandmarkIdGet(landmarkId: number, options?: AxiosRequestConfig) {
        return LandmarksApiFp(this.configuration).getLandmarksLandmarkIdLandmarksLandmarkIdGet(landmarkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Landmarks
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandmarksApi
     */
    public getLandmarksLandmarksGet(pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return LandmarksApiFp(this.configuration).getLandmarksLandmarksGet(pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Landmarks Landmark Id
     * @param {number} landmarkId 
     * @param {LandmarkPatch} landmarkPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandmarksApi
     */
    public patchLandmarksLandmarkIdLandmarksLandmarkIdPatch(landmarkId: number, landmarkPatch: LandmarkPatch, options?: AxiosRequestConfig) {
        return LandmarksApiFp(this.configuration).patchLandmarksLandmarkIdLandmarksLandmarkIdPatch(landmarkId, landmarkPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Landmarks
     * @param {LandmarkCreate} landmarkCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandmarksApi
     */
    public postLandmarksLandmarksPost(landmarkCreate: LandmarkCreate, options?: AxiosRequestConfig) {
        return LandmarksApiFp(this.configuration).postLandmarksLandmarksPost(landmarkCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RootApi - axios parameter creator
 * @export
 */
export const RootApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerRegisterPost: async (userCreate: UserCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('registerRegisterPost', 'userCreate', userCreate)
            const localVarPath = `/register/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Token
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenTokenPost: async (username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('tokenTokenPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('tokenTokenPost', 'password', password)
            const localVarPath = `/token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RootApi - functional programming interface
 * @export
 */
export const RootApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RootApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readRootGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Root>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readRootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerRegisterPost(userCreate: UserCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerRegisterPost(userCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Token
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenTokenPost(username, password, grantType, scope, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RootApi - factory interface
 * @export
 */
export const RootApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RootApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootGet(options?: any): AxiosPromise<Root> {
            return localVarFp.readRootGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerRegisterPost(userCreate: UserCreate, options?: any): AxiosPromise<User> {
            return localVarFp.registerRegisterPost(userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Token
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.tokenTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RootApi - object-oriented interface
 * @export
 * @class RootApi
 * @extends {BaseAPI}
 */
export class RootApi extends BaseAPI {
    /**
     * 
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RootApi
     */
    public readRootGet(options?: AxiosRequestConfig) {
        return RootApiFp(this.configuration).readRootGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RootApi
     */
    public registerRegisterPost(userCreate: UserCreate, options?: AxiosRequestConfig) {
        return RootApiFp(this.configuration).registerRegisterPost(userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Token
     * @param {string} username 
     * @param {string} password 
     * @param {string} [grantType] 
     * @param {string} [scope] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RootApi
     */
    public tokenTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig) {
        return RootApiFp(this.configuration).tokenTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SeriesApi - axios parameter creator
 * @export
 */
export const SeriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Series Series Id
         * @param {number} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeriesSeriesIdSeriesSeriesIdDelete: async (seriesId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('deleteSeriesSeriesIdSeriesSeriesIdDelete', 'seriesId', seriesId)
            const localVarPath = `/series/{series_id}/`
                .replace(`{${"series_id"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Landmarks Landmark Id Series
         * @param {number} landmarkId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesGet: async (landmarkId: number, pageToken?: string, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landmarkId' is not null or undefined
            assertParamExists('getLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesGet', 'landmarkId', landmarkId)
            const localVarPath = `/landmarks/{landmark_id}/series/`
                .replace(`{${"landmark_id"}}`, encodeURIComponent(String(landmarkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Series Series Id
         * @param {number} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesSeriesIdSeriesSeriesIdGet: async (seriesId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('getSeriesSeriesIdSeriesSeriesIdGet', 'seriesId', seriesId)
            const localVarPath = `/series/{series_id}/`
                .replace(`{${"series_id"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Series Series Id
         * @param {number} seriesId 
         * @param {SeriesPatch} seriesPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSeriesSeriesIdSeriesSeriesIdPatch: async (seriesId: number, seriesPatch: SeriesPatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('patchSeriesSeriesIdSeriesSeriesIdPatch', 'seriesId', seriesId)
            // verify required parameter 'seriesPatch' is not null or undefined
            assertParamExists('patchSeriesSeriesIdSeriesSeriesIdPatch', 'seriesPatch', seriesPatch)
            const localVarPath = `/series/{series_id}/`
                .replace(`{${"series_id"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seriesPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Landmarks Landmark Id Series
         * @param {number} landmarkId 
         * @param {SeriesCreate} seriesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesPost: async (landmarkId: number, seriesCreate: SeriesCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landmarkId' is not null or undefined
            assertParamExists('postLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesPost', 'landmarkId', landmarkId)
            // verify required parameter 'seriesCreate' is not null or undefined
            assertParamExists('postLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesPost', 'seriesCreate', seriesCreate)
            const localVarPath = `/landmarks/{landmark_id}/series/`
                .replace(`{${"landmark_id"}}`, encodeURIComponent(String(landmarkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seriesCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SeriesApi - functional programming interface
 * @export
 */
export const SeriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SeriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Series Series Id
         * @param {number} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSeriesSeriesIdSeriesSeriesIdDelete(seriesId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSeriesSeriesIdSeriesSeriesIdDelete(seriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Landmarks Landmark Id Series
         * @param {number} landmarkId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesGet(landmarkId: number, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeriesCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesGet(landmarkId, pageToken, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Series Series Id
         * @param {number} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeriesSeriesIdSeriesSeriesIdGet(seriesId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Series>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeriesSeriesIdSeriesSeriesIdGet(seriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Series Series Id
         * @param {number} seriesId 
         * @param {SeriesPatch} seriesPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSeriesSeriesIdSeriesSeriesIdPatch(seriesId: number, seriesPatch: SeriesPatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Series>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSeriesSeriesIdSeriesSeriesIdPatch(seriesId, seriesPatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post Landmarks Landmark Id Series
         * @param {number} landmarkId 
         * @param {SeriesCreate} seriesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesPost(landmarkId: number, seriesCreate: SeriesCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Series>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesPost(landmarkId, seriesCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SeriesApi - factory interface
 * @export
 */
export const SeriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SeriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Series Series Id
         * @param {number} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeriesSeriesIdSeriesSeriesIdDelete(seriesId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteSeriesSeriesIdSeriesSeriesIdDelete(seriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Landmarks Landmark Id Series
         * @param {number} landmarkId 
         * @param {string} [pageToken] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesGet(landmarkId: number, pageToken?: string, pageSize?: number, options?: any): AxiosPromise<SeriesCollection> {
            return localVarFp.getLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesGet(landmarkId, pageToken, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Series Series Id
         * @param {number} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesSeriesIdSeriesSeriesIdGet(seriesId: number, options?: any): AxiosPromise<Series> {
            return localVarFp.getSeriesSeriesIdSeriesSeriesIdGet(seriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Series Series Id
         * @param {number} seriesId 
         * @param {SeriesPatch} seriesPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSeriesSeriesIdSeriesSeriesIdPatch(seriesId: number, seriesPatch: SeriesPatch, options?: any): AxiosPromise<Series> {
            return localVarFp.patchSeriesSeriesIdSeriesSeriesIdPatch(seriesId, seriesPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Landmarks Landmark Id Series
         * @param {number} landmarkId 
         * @param {SeriesCreate} seriesCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesPost(landmarkId: number, seriesCreate: SeriesCreate, options?: any): AxiosPromise<Series> {
            return localVarFp.postLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesPost(landmarkId, seriesCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SeriesApi - object-oriented interface
 * @export
 * @class SeriesApi
 * @extends {BaseAPI}
 */
export class SeriesApi extends BaseAPI {
    /**
     * 
     * @summary Delete Series Series Id
     * @param {number} seriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public deleteSeriesSeriesIdSeriesSeriesIdDelete(seriesId: number, options?: AxiosRequestConfig) {
        return SeriesApiFp(this.configuration).deleteSeriesSeriesIdSeriesSeriesIdDelete(seriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Landmarks Landmark Id Series
     * @param {number} landmarkId 
     * @param {string} [pageToken] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public getLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesGet(landmarkId: number, pageToken?: string, pageSize?: number, options?: AxiosRequestConfig) {
        return SeriesApiFp(this.configuration).getLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesGet(landmarkId, pageToken, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Series Series Id
     * @param {number} seriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public getSeriesSeriesIdSeriesSeriesIdGet(seriesId: number, options?: AxiosRequestConfig) {
        return SeriesApiFp(this.configuration).getSeriesSeriesIdSeriesSeriesIdGet(seriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Series Series Id
     * @param {number} seriesId 
     * @param {SeriesPatch} seriesPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public patchSeriesSeriesIdSeriesSeriesIdPatch(seriesId: number, seriesPatch: SeriesPatch, options?: AxiosRequestConfig) {
        return SeriesApiFp(this.configuration).patchSeriesSeriesIdSeriesSeriesIdPatch(seriesId, seriesPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Landmarks Landmark Id Series
     * @param {number} landmarkId 
     * @param {SeriesCreate} seriesCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesApi
     */
    public postLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesPost(landmarkId: number, seriesCreate: SeriesCreate, options?: AxiosRequestConfig) {
        return SeriesApiFp(this.configuration).postLandmarksLandmarkIdSeriesLandmarksLandmarkIdSeriesPost(landmarkId, seriesCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Reset User Password
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPasswordResetPasswordPost: async (resetPasswordRequest: ResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('resetUserPasswordResetPasswordPost', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/reset_password/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication _Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "_Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Reset User Password
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserPasswordResetPasswordPost(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserPasswordResetPasswordPost(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Reset User Password
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPasswordResetPasswordPost(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<ResetPasswordResponse> {
            return localVarFp.resetUserPasswordResetPasswordPost(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserGet(options?: any): AxiosPromise<User> {
            return localVarFp.userUserGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Reset User Password
     * @param {ResetPasswordRequest} resetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetUserPasswordResetPasswordPost(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetUserPasswordResetPasswordPost(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userUserGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userUserGet(options).then((request) => request(this.axios, this.basePath));
    }
}


